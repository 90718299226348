import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ImageGridItemPackage from "../../components/image-grid-item-package"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import Testimonials from "../../components/testimonials"


class ShopifyPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Shopify Developer Bournemouth" />
        <HeroAlt 
          image="/shopify-service.jpg"
          title="Shopify"
          subtitle="At Old Salt, we’re specialists in Shopify and have been working with the platform for nearly 5 years. We can help you create a Shopify website from start to finish."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Start selling</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>With over 8 years of experience in ecommerce, We have been fortunate enough to work with some incredible brands to improve the effectiveness of their e-commerce websites including Paco Rabanne, Wedgwood and Finisterre.</p>
                <p>Shopify should be your first choice when considering an ecommerce platform. It is one of the most scalable, feature-rich and fast-growing ecommerce platforms on the market. With over one million businesses choosing Shopify including some of the world’s largest brands it is more than capable of helping you reach new audiences and sell your wares.</p>
                <p>We can help get your Shopify store up and running from start to finish and provide full training as standard on all Shopify projects.</p>
                <Link className="button gm-top--1" to="/contact">Start today</Link>
              </div>              
            </div>
          </div>
        </div>

        <Spacer space="2" />

        <WorkGrid>
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Shopify" title="Basic" price="Starting from £1150" text="<ul><li>Shopify Theme Installation</li><li>Store setup</li><li>Training</li></ul>" link="/contact" />
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Shopify" title="Premium" price="Starting from £1750" text="<ul><li>Shopify Theme Installation</li><li>Store setup</li><li>Theme customisation</li><li>App installation</li><li>Training</li></ul>" link="/contact" />
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Shopify" title="Bespoke" price="On Request" text="<p>Bespoke Shopify store to your specific requirements and needs.</p>" link="/contact" />
        </WorkGrid>
       
       <Spacer space="4" />

        <Testimonials />

        <Spacer space="4" />

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="shopify" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
        
         <Spacer space="1" />

        <div className="row align-middle shrink-width gm-top--1 gm-bottom--1">
          <div className="column small-12 medium-6 medium-text-left text-center">
            <h3 className="no-margin-bottom button-title">Our Work</h3>
            <p className="gm-bottom--1">Below is a selection of some of the Shopify work that we've produced here at Old Salt.</p>
          </div>
          <div className="column small-12 medium-6 text-center medium-text-right">
            <Link to="/work" className="button no-margin-bottom">View work</Link>
          </div>
        </div>

        <WorkGrid>
          <ImageGridItem width={7} image={ '/surfdurt.jpg' } subtitle="Shopify" title="SurfDurt" link="https://www.surfdurt.com/" showLink="true" />
          <ImageGridItem width={5} image={ '/connock.jpg' } subtitle="Shopify" title="Connock London" link="https://www.connocklondon.co.uk/" showLink="true" />
          <ImageGridItem width={5} image={ '/allett.jpg' } subtitle="Shopify" title="Allett Wallets" link="https://www.all-ett.com/" showLink="true" />          
          <ImageGridItem width={7} image={ '/cashmirino.jpg' } subtitle="Shopify" title="Cashmirino" link="https://www.cashmirino.com/" showLink="true" />
        </WorkGrid> 


        <Spacer space="2" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default ShopifyPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`